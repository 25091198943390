.trainingactionbar-root {
  display: flex;
  height: 72px;
  background-color: white;
  border-bottom: 2px solid var(--pits-menu-border);
  align-items: center;
  font-family: "Poppins";
}

.trainingactionbar-name {
  width: 292px;
  height: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  color: var(--pi-text-color-1);
  
}

.trainingactionbar-training-wrapper {
  min-width: 235px;
  max-width: 235px;
  margin-left: 17px;
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}

.trainingactionbar-training-unsaved {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  flex-basis: 60%;
  text-align: left;
  font-style: italic;

  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow-x: hidden;
  overflow-y: hidden;
  color: var(--pi-interactionpicker-icon);
}

.trainingactionbar-undoredo {
  display: flex;
  margin-left: 32px;
  display: none;
}

.trainingactionbar-undoredo>*:first-child {
  margin-right: 16px;
}

.trainingactionbar-undoredo-button {
  width: 120px;
}

.trainingactionbar-undoredo-button :first-child {
  margin-right: 12px;
}

.trainingactionbar-test-button {
  margin-left: 32px;
}

.trainingactionbar-localization {
  margin-left: auto;
  margin-right: 16px;
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 32px;
}

.trainingactionbar-localization-dropdown {
  width: 200px;
}

.trainingactionbar-localization-button {
  color: white;
  border-radius: 100%;
  background-color: var(--pi-key-blue);
}