.pi-inputfield-root {
  position: relative;
  --border-color: var(--pi-key-blue);
}

.pi-inputfield-disabled {
  --border-color: var(--pi-disabled) !important;
}

.pi-inputfield-label {
  margin-bottom: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.pi-inputfield-input {
  padding-left: 8px;
  border: none;
  width: 100%;
  font-size: 15px;
  font-family: "Poppins";
  color: var(--pi-key-blue);
}

.pi-inputfield-input:disabled {
  background-color: transparent;
  color: var(--border-color);
}

.pi-inputfield-input:focus {
  outline: none;
}

.pi-inputfield-input::placeholder {
  color: var(--pi-menu-text-color-1);
}

.pi-inputfield-info {
  position: absolute;
  right: 5px;
  top: 26px;
}

.pi-inputfield-wrapper {
  border: 2px solid var(--border-color);
  border-radius: 28px;
  height: 36px;
  box-sizing: border-box;
  width: 100%;

  display: flex;
  justify-content: start;
  padding: 8px 4px 8px 4px;

  background-color: white;
}

.pi-inputfield-searchicon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 4px;
  color: var(--border-color);
}
