.connectquizgraphicsanswer-root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  position: relative;
}

.connectquizgraphicsanswer-dot-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10%;
}

.connectquizgraphicsanswer-image-wrapper {
  border: 2px solid var(--pi-game-blue);
  border-radius: 5px;
  width: 100%;
  max-height: 85%;
  min-height: 85%;
  background-color: var(--pi-game-blue-faded);
  position: relative;
}

.connectquizgraphicsanswer-select-image {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  color: var(--pi-game-blue);
  cursor: pointer;
}

.connectquizgraphicsanswer-select-image-button {
  position: absolute;
  bottom: 8px;
  right: 8px;
  border-radius: 100%;
  color: white;
  background-color: var(--pi-game-blue);
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
}
.connectquizgraphicsanswer-select-image-button:hover {
  opacity: 0.75;
}

.connectquizgraphicsanswer-display-image {
  position: relative;
  width: 100%;
  /* height: calc(100% - 32px);
  top: 32px; */
  height: 100%;
  min-width: 20%;
  margin: auto;
  display: flex;
  justify-content: center;
  justify-items: center;
}

.connectquizgraphicsanswer-display-image img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: var(--pi-game-blue-faded);
  border-radius: 5px;
}

.connectquizgraphicsanswer-select-image:hover {
}

.connectquizgraphicsanswer-delete-button {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 24px;
  color: var(--pi-error);
  cursor: pointer;
  z-index: 10;
}

.connectquizgraphicsanswer-delete-button:hover {
  color: var(--pi-error-faded-2);
}
