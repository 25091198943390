.traininglocalizationpicker-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  color: var(--pi-key-blue);
  margin-left: 8px;
}

.traininglocalizationpicker-header {
  font-family: "Poppins";
  padding: 16px;
  font-size: 28px;
  font-weight: 600;
  color: var(--pi-text-color-1);
  display: flex;
  border-bottom: 2px solid var(--pits-menu-border);
}

.traininglocalizationdialog-body {
  height: 100%;
}

.traininglocalizationdialog-add-button {
  width: 20%;
  padding-top: 16px;
  margin: auto;
}




.traininglocalizationdialog-scroller {
  height: calc(100% - 64px);
  overflow-y: auto;
  margin-right: 16px;
}

/* output */
/* .traininglocalizationdialog-scroller::-webkit-scrollbar-thumb {
  background-color: var(--pi-key-blue);
  border-radius: 10px;
}
.traininglocalizationdialog-scroller::-webkit-scrollbar-track {
  background-color: var(--pi-scrollbar-bg);
  border-radius: 10px;
}
.traininglocalizationdialog-scroller::-webkit-scrollbar-corner {
  background-color: var(--pi-scrollbar-bg);
  border-radius: 10px;
}
.traininglocalizationdialog-scroller::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
} */
