.pisplitbutton-icon {
  color: var(--pi-text-color-1);
}

.pisplitbutton-main-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pisplitbutton-main-text {
  color: var(--pi-text-color-1);
  font-weight: 600;
}
