.pidialog-close-button {
  right: 29.5px;
  top: 37.5px;
  position: absolute !important;
}

.pidialog-close-button:hover {
  background-color: var(--pi-main-hover) !important;
}

.pidialog-body {
  display: flex;
  flex-direction: column;
  height: calc(100% - 24px);
  max-height: calc(100% - 24px);
  overflow-y: auto;
  margin: 0 24px;
}
