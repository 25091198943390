.publishdialog-root {
  height: 90vh;
  display: flex;
  position: relative;
}

.publishdialog-closebutton {
  position: absolute;
  top: 32px;
  right: 32px;
  z-index: 100;
}

.publishdialog-banner {
  width: 30%;
  height: 100%;
  border-radius: 10px;
  background-color: var(--pi-game-blue-faded);
  display: flex;
  justify-content: center;
  align-content: center;
}
.publishdialog-banner img {
  object-fit: contain;
  width: 50%;
  height: 50%;
  margin: auto;
}

.publishdialog-body {
  width: 70%;
}

.publishdialog-content {
  padding: 32px;
  height: calc(90vh - 104px);
}

.publishdialog-title {
  display: flex;
  gap: 8px;
  font-size: 28px;
  color: var(--pi-text-color-1);
  font-weight: 600;
  line-height: 38px;
  margin-right: 48px;
}

.publishdialog-training-name {
  color: var(--pi-key-blue);
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.publishdialog-button {
  position: absolute;
  bottom: 32px;
  right: 32px;
  width: 128px;
}

.publishdialog-course-translations {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: calc(100% - 38px);
  overflow-y: auto;
  overflow-x: hidden;
}

.publishdialog-course-translations {
  padding-right: 12px;
}

.publishdialog-loading-indicator {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--pi-key-blue);
  font-size: 48px;
}

.publishdialog-loading-publish-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -75%);
}

.publishdialog-loading-publish-text {
  color: var(--pi-text-color-1);
  font-size: 16px;
  font-weight: 500;
}

.publishdialog-completed-card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.publishdialog-failed {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--pi-key-blue);
}

.publishdialog-failed-icon {
  font-size: 200px;
  color: var(--pi-error);
  height: 220px;
}

.publishdialog-failed-text {
  font-size: 32px;
  color: var(--pi-error);
}

.publishdialog-processing-icon {
  font-size: 200px;
  color: var(--pi-game-orange);
  height: 220px;
}

.publishdialog-processing-text {
  font-size: 30px;
  color: var(--pi-game-orange);
  text-align: center;
}
